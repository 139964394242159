<template>
  <div id="holdings_module">
    <div class="px-4 py-2 d-flex align-center">
      <div class="font-weight-bold primaryColor">Holdings</div>
      <v-progress-circular
        class="ml-2"
        indeterminate
        v-if="fundsOrHoldingsLoader"
        size="18"
        :width="2"
        color="blue"
      ></v-progress-circular>
    </div>
    <v-row class="mb-2 mx-0 mt-0 px-4">
      <v-slide-group v-model="infoType" class="pa-0" mandatory hide-arrows>
        <v-slide-item
          v-for="(item, i) in tabList"
          :key="i"
          v-slot="{ active, toggle }"
        >
          <v-btn
            depressed
            :color="active ? 'activeColor white--text' : menuColors()"
            :id="`${item.name}_btn`"
            height="26"
            min-width="50"
            class="fsize12 rounded-sm text-center text-capitalize"
            :value="item.name"
            @click="toggle"
          >
            {{ item.name }}
            <span v-if="item.length != 0">&nbsp;({{ item.length }})</span>
          </v-btn>
        </v-slide-item>
      </v-slide-group>

      <v-spacer></v-spacer>

      <span
        class="float-right d-flex align-center"
        id="searchBarAndIcons"
        v-if="holdingList.length > 0"
      >
        <span class="search-box mx-2" v-if="!$store.state.isMobileView">
          <span class="searchwhole">
            <customIcon
              :name="'search'"
              class="searchimg"
              :width="'16'"
              :height="'16'"
              :color="$store.state.amogaColor"
            />
            <span class="searchinput">
              <input
                id="holdingSearch"
                class="placeholdsearch"
                placeholder="Search"
                autocorrect="off"
                v-model="searchHolding"
                oninput="this.value = this.value.toUpperCase()"
              />
            </span>
          </span>
        </span>
        <v-btn
          v-if="authorizeList.length > 0"
          height="24"
          min-width="80"
          depressed
          :color="$store.state.buttonThemeColor"
          class="fsize14 mr-2 pa-0 text-capitalize white--text"
          id="auth_revoke_btn"
        >
          <span
            class="d-flex rounded px-1 cursor"
            @click="callAuthDialog('Auth')"
          >
            <span
              ><customIcon
                :name="'unlock'"
                class="mx-1 cursor d-flex"
                :width="'16'"
                :height="'16'"
                :color="'#ffffff'"
            /></span>
            <span class="fsize12 mr-1 white--text">Authorize</span></span
          ></v-btn
        >
        <v-btn
          id="revoke_btn"
          v-if="revocationList.length > 0"
          height="24"
          min-width="80"
          depressed
          :color="$store.state.buttonThemeColor"
          class="fsize14 mr-2 pa-0 text-capitalize white--text"
        >
          <span
            class="d-flex rounded px-1 cursor"
            @click="callAuthDialog('Revoke')"
          >
            <span
              ><customIcon
                :name="'unlock'"
                class="mx-1 cursor d-flex"
                :width="'16'"
                :height="'16'"
                :color="'#ffffff'"
            /></span>
            <span class="fsize12 mr-1 white--text">Revoke </span></span
          ></v-btn
        >
        <span  class="d-flex">
          <v-slide-group v-model="newtag" class="pa-0" mandatory hide-arrows>
            <v-slide-item
              v-for="(item, i) in tags"
              :key="i"
              v-slot="{ active, toggle }"
            >
              <span @click="changeTag(item)">
                <v-btn
                  depressed
                  :color="active ? 'activeColor white--text' : menuColors()"
                  :id="`${item}_btn`"
                  height="26"
                  min-width="50"
                  class="fsize12 rounded-sm text-center text-capitalize mr-2"
                  :value="item"
                  @click="toggle"
                >
                  {{ item }}
                </v-btn>
              </span>
            </v-slide-item>
          </v-slide-group>

<!-- Setting  -->
          <!-- <div
            class="text-center pa-0 ma-0 pl-2 d-flex justify-end align-center mr-2"
          >
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="300"
              offset-y
              content-class="settingsMenu"
            >
              <template v-slot:activator="{ on, attrs }">
                <span depressed v-bind="attrs" v-on="on"> 
                  <customIcon
                    :name="'settings'"
                    class="mx-1 cursor d-flex"
                    :width="'21'"
                    :height="'21'"
                    :color="$store.state.amogaColor"
                  />
                </span>
              </template>

              <v-card class="pa-2 overflowscroll" min-height="200">
                <v-col class="col-12">
                  <div>
                    <v-card class="addRemoveCard" height="320" width="230">
                      <div class="text-h6">Add/Remove Column</div>
                      <div
                        class="fsize12"
                        v-for="(item, index) in holdingsHeaderSelection"
                        :key="holdingsHeaderSelection[index].value"
                      >
                        <v-checkbox
                          class="pa-1"
                          dense
                          :ripple="false"
                          hide-details
                          :label="item.text"
                          v-model="item.selected"
                          :disabled="item.disabled"
                          @change="colUpdate"
                        ></v-checkbox>
                      </div>
                    </v-card>
                  </div>
                </v-col>
              </v-card>
            </v-menu>
          </div> -->
<!-- Setting End -->

          <v-tooltip
            top
            :color="
              $vuetify.theme.dark
                ? 'darktoolTipColor toolTipTop darkTooltipTop'
                : 'lighttoolTipColor toolTipTop lightTooltipTop'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <a id="hold" class="d-flex align-center">
                <span
                  v-bind="attrs"
                  v-on="on"
                  v-if="!$store.state.isMobileView"
                  @click="
                    checkDownload(
                      'holdings',
                      'holdings',
                      searchHolding ? holdingSearchFilter : holdingList, newtag
                    )
                  "
                  id="hold_download_icon"
                >
                  <customIcon
                    :name="'download'"
                    class="mx-1 cursor d-flex"
                    :width="'21'"
                    :height="'21'"
                    :color="$store.state.amogaColor"
                  />
                </span>
              </a>
            </template>
            <span>Download</span>
          </v-tooltip>
        </span>
      </span>
    </v-row>

    <v-divider class="mx-4 mb-2"></v-divider>
    <!-- v-if="showList.length != 0" -->

    <v-card
      class="ma-4 pa-4"
      v-if="
        (holdingList.length != 0 &&
          this.searchHolding == '' &&
          !this.fundsOrHoldingsLoader &&
          $store.state.isMobileView) ||
        (this.searchHolding !== '' &&
          holdingSearchFilter.length != 0 &&
          !this.fundsOrHoldingsLoader &&
          $store.state.isMobileView)
      "
    >
      <div class="row ma-0 pa-0 d-flex-align-center">
        <div class="fsize12 col-6 ma-0 pa-0" id="hold_invest_label">
          Invested
        </div>
        <div class="fsize12 col-6 ma-0 pa-0" id="hold_current_label">
          Current
        </div>
      </div>
      <div
        class="row mx-0 mb-2 mt-0 px-0 pb-2 pt-0 d-flex-align-center border-bottom-mwSearch"
      >
        <div class="fsize10 col-6 ma-0 pa-0 primaryColor" id="hold_invest_val">
          {{ ruppesFormat(totalInvestment.toFixed(2)) }}
        </div>
        <div class="fsize10 col-6 ma-0 pa-0 primaryColor" id="hold_current_val">
          {{ ruppesFormat(totalcurrentValue.toFixed(2)) }}
        </div>
      </div>
      <div class="row ma-0 pa-0 d-flex-align-center">
        <div class="fsize12 col-6 ma-0 pa-0" id="hold_profit_and_loss_label">
          P&L
        </div>
        <div
          class="fsize12 col-6 ma-0 pa-0"
          id="hold_today_profit_and_loss_label"
        >
          Today's P&L
        </div>
      </div>
      <div class="row ma-0 pa-0 d-flex-align-center">
        <div class="fsize10 col-6 ma-0 pa-0">
          <div
            class="mb-1"
            :class="totalPnl >= 0 ? 'positiveColor' : 'negativeColor'"
            id="hold_tot_pnl_label"
          >
            {{ ruppesFormat(totalPnl.toFixed(2)) }}
            <span class="ml-2 positiveColor" v-if="totalPnlChange == 'NA'">{{
              totalPnlChange
            }}</span>
            <span
              class="ml-2"
              v-else
              :class="totalPnlChange >= 0 ? 'positiveColor' : 'negativeColor'"
              >({{ totalPnlChange.toFixed(2) }}%)</span
            >
          </div>
        </div>
        <div class="fsize10 col-6 ma-0 pa-0 primaryColor">
          <div
            class="mb-1"
            :class="daysPnl >= 0 ? 'positiveColor' : 'negativeColor'"
            id="dayspnl_label"
          >
            {{ ruppesFormat(daysPnl.toFixed(2))
            }}<span
              class="ml-2"
              :class="daysPnlChange >= 0 ? 'positiveColor' : 'negativeColor'"
              >({{ daysPnlChange.toFixed(2) }}%)</span
            >
          </div>
        </div>
      </div>
    </v-card>
    <v-card
      class="ma-4"
      v-if="
        (holdingList.length != 0 &&
          this.searchHolding == '' &&
          !this.fundsOrHoldingsLoader) ||
        (this.searchHolding !== '' &&
          holdingSearchFilter.length != 0 &&
          !this.fundsOrHoldingsLoader)
      "
    >
      <v-simple-table
        id="holdings_table"
        class="border-bottom-light primaryColor overflow-y-auto"
        :fixed-header="true"
        height="auto"
        :class="{ overflowinherit: isDropped }"
        v-if="!$store.state.isMobileView"
      >
        <thead @mouseleave="headerIndex = -1" id="holdings_table_thead">
          <template>
            <td
              class="px-4 fsize12 cursor border-bottom-table selectRow"
              :class="head.class"
              :id="head.text"
              style="height: 48px"
              @mouseenter="headerIndex = index"
              v-for="(head, index) in headers"
              :key="index"
              @click="head.sortable ? changeSort(head.value) : ''"
            >
              <span>
                <span v-if="head.text == 'Instrument'" :class="head.align"
                  ><span>{{ head.text }}</span>
                  <span :class="head.subClass"
                    ><span
                      v-if="
                        head.isSort || (head.sortable && headerIndex == index)
                      "
                      ><v-icon small>{{
                        head.isdesc ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon></span
                    ></span
                  >
                </span>
                <span v-else :class="head.align"
                  ><span :class="head.subClass"
                    ><span
                      v-if="
                        head.isSort || (head.sortable && headerIndex == index)
                      "
                      ><v-icon small>{{
                        head.isdesc ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon></span
                    ></span
                  >
                  <span>{{ head.text }}</span></span
                >
              </span>
            </td>
          </template>
        </thead>

        <tbody
          id="holdings_table_tbody"
          class="primaryColor"
          @mouseleave="
            currentIndex = -1;
            isDropped = false;
          "
        >
          <tr
            v-for="(item, index) in this.searchHolding == ''
              ? holdingList
              : holdingSearchFilter"
            :key="index"
            class="hoverParent tableRow"
            @mouseenter="
              isDropped = false;
              currentIndex = index;
            "
            :id="`${index}_holdings_table_tbody_tr`"
          >
            <td
              class="text-left px-4 py-2 pos-reletive tableContent"
              :id="`${index}_holdings_table_scrip_name`"
              v-if="holdingsHeaderSelection['formatScripName']['selected']"
            >
              {{ item.formatScripName }}
              <div
                class="dropdown position-absolute right-0"
                v-if="currentIndex == index"
              >
                <v-tooltip
                  top
                  :color="
                    $vuetify.theme.dark
                      ? 'darktoolTipColor toolTipTop darkTooltipTop'
                      : 'lighttoolTipColor toolTipTop lightTooltipTop'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :id="`${index}_holdings_table_opt`"
                      @mouseover="
                        getWindowHeight(`${index}_holdings_table_opt`)
                      "
                      @click="isDropped = !isDropped"
                      min-width="50"
                      max-width="50"
                      height="23"
                      outlined
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex align-center justify-center pos-reletive fsize12 text-capitalize border-0075e1 color-0075e1 rounded-sm background-white"
                      >More
                    </v-btn>
                  </template>
                  <span>Options</span>
                </v-tooltip>

                <transition name="slide">
                  <ul
                    :id="`${index}_holdings_table_opt_ul`"
                    v-if="currentIndex == index && isDropped"
                    :class="isRotate ? 'bottom25' : ''"
                    class="pa-0 list expectMkWatchMoreList"
                  >
                    <li
                      @click="
                        isDropped = false;
                        callOrderWindow('sell', item);
                      "
                      class="primaryColor fsize12 cursor"
                      :id="`${index}_holdings_table_opt_exit`"
                    >
                      Exit
                    </li>
                    <li
                      @click="
                        isDropped = false;
                        callOrderWindow('buy', item);
                      "
                      class="primaryColor fsize12 cursor"
                      :id="`${index}_holdings_table_opt_add`"
                    >
                      Add
                    </li>
                    <li
                      @click="
                        isDropped = false;
                        callTrandingViewChart(item, 'holdings');
                      "
                      class="primaryColor fsize12 cursor"
                      :id="`${index}_holdings_table_opt_chart`"
                    >
                      Chart
                    </li>
                  </ul>
                </transition>
              </div>
            </td>
            <td
              class="text-right px-4 py-2 tableContent"
              :id="`${index}_holdings_table_qty`"
              v-if="holdingsHeaderSelection['showQty']['selected']"
            >
              <span v-if="parseInt(item.Btst) != 0">
                <span v-if="parseInt(item.showQty) != 0"
                  >{{ item.showQty }} &nbsp;</span
                >( T1: {{ item.Btst }} )</span
              >
              <span v-if="parseInt(item.Btst) == 0">{{ item.showQty }}</span>
            </td>
            <td
              class="text-right px-4 py-2 tableContent"
              :id="`${index}_holdings_table_price`"
              v-if="holdingsHeaderSelection['Price']['selected']"
            >
              {{ item["Price"] }}
            </td>
            <td
              class="text-right px-4 py-2 tableContent"
              :id="`${index}_holdings_table_buy_val`"
              v-if="holdingsHeaderSelection['buyValue']['selected']"
            >
              {{ item.buyValue }}
            </td>
            <td
              class="text-right px-4 py-2 tableContent"
              :id="`${index}_holdings_table_lp`"
              v-if="holdingsHeaderSelection['ltp']['selected']"
            >
              {{ item.ltp }}
            </td>
            <td
              class="text-right px-4 py-2 tableContent"
              :id="`${index}_holdings_table_pdc`"
              v-if="holdingsHeaderSelection['pdc']['selected']"
            >
              {{ item.pdc }}
            </td>
            <td
              class="text-right px-4 py-2 tableContent font-weight-600"
              :class="
                parseFloat(item.pnl) >= 0 ? 'positiveColor' : 'negativeColor'
              "
              :id="`${index}_holdings_table_pnl`"
              v-if="holdingsHeaderSelection['pnl']['selected']"
            >
              {{ ruppesFormat(item.pnl) }}
            </td>
            <td
              class="text-right px-4 py-2 tableContent font-weight-600"
              :class="
                parseFloat(item.daypnl) >= 0 ? 'positiveColor' : 'negativeColor'
              "
              :id="`${index}_holdings_table_pnl`"
              v-if="holdingsHeaderSelection['daypnl']['selected']"
            >
              {{ ruppesFormat(item.daypnl) }}
            </td>
            <td
              class="text-right px-4 py-2 tableContent fsize11"
              :class="
                parseFloat(item.perChg) >= 0 ? 'positiveColor' : 'negativeColor'
              "
              :id="`${index}_holdings_table_per_chg`"
              v-if="holdingsHeaderSelection['perChg']['selected'] && newtag == 0"
            >
              {{ item.perChg }}%
            </td>
            <td
              class="text-right px-4 py-2 tableContent fsize11"
              :class="
                parseFloat(item.dayChg) >= 0 ? 'positiveColor' : 'negativeColor'
              "
              :id="`${index}_holdings_table_per_chg`"
             v-else
            >
              {{ item.dayChg }}%
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="">
            <td colspan="9">
              <v-row class="ma-0 pa-6 w-100">
                <v-col class="pa-0">
                  <div
                    class="secondaryColor fsize12 mb-1"
                    :id="`holdings_table_tot_invest_label`"
                  >
                    Total Investment
                  </div>
                  <div
                    class="fsize20 primaryColor font-weight-600"
                    id="holdings_table_tot_invest_val"
                  >
                    {{ ruppesFormat(totalInvestment.toFixed(2)) }}
                  </div>
                </v-col>
                <v-col class="pa-0">
                  <div
                    class="secondaryColor fsize12 mb-1"
                    id="holdings_table_tot_current_val_label"
                  >
                    Current value
                  </div>
                  <div
                    class="fsize20 font-weight-600"
                    id="holdings_table_tot_current_val"
                  >
                    {{ ruppesFormat(totalcurrentValue.toFixed(2)) }}
                  </div>
                </v-col>
                <v-col class="pa-0">
                  <div
                    class="secondaryColor fsize12 mb-1"
                    id="holdings_table_tot_day's_profit_and_loss"
                  >
                    Day's P&L
                  </div>
                  <div
                    id="holdings_table_tot_day's_profit_and_loss_val"
                    class="fsize20 mb-1 font-weight-600"
                    :class="daysPnl >= 0 ? 'positiveColor' : 'negativeColor'"
                  >
                    {{ ruppesFormat(daysPnl.toFixed(2)) }}
                  </div>
                  <div
                    id="holdings_table_tot_day's_profit_and_loss_chg_val"
                    class="fsize14 font-weight-600"
                    :class="
                      daysPnlChange >= 0 ? 'positiveColor' : 'negativeColor'
                    "
                  >
                    ({{ daysPnlChange.toFixed(2) }}%)
                  </div>
                </v-col>
                <v-col class="pa-0">
                  <div
                    class="secondaryColor fsize12 mb-1"
                    id="holdings_table_tot_profit_and_loss_label"
                  >
                    Total P&L
                  </div>
                  <div
                    id="holdings_table_tot_profit_and_loss_val"
                    class="fsize20 mb-1 font-weight-600"
                    :class="totalPnl >= 0 ? 'positiveColor' : 'negativeColor'"
                  >
                    {{ ruppesFormat(totalPnl.toFixed(2)) }}
                  </div>
                  <div
                    id="holdings_table_tot_profit_and_loss_chg_val"
                    class="fsize14 positiveColor font-weight-600"
                    v-if="totalPnlChange == 'NA'"
                  >
                    {{ totalPnlChange }}
                  </div>
                  <div
                    class="fsize14 font-weight-600"
                    v-else
                    :class="
                      totalPnlChange >= 0 ? 'positiveColor' : 'negativeColor'
                    "
                  >
                    ({{ totalPnlChange.toFixed(2) }}%)
                  </div>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tfoot>
      </v-simple-table>

      <div v-if="$store.state.isMobileView">
        <div
          class="row ma-0 px-3 py-2 border-bottom-mwSearch"
          v-for="(item, index) in this.searchHolding == ''
            ? holdingList
            : holdingSearchFilter"
          :key="index"
          @click="callBottomSheet(item)"
          :id="`${index}_holdings_table_div_row`"
        >
          <div
            class="row pa-0 ma-0 d-flex align-center justify-space-between w-100"
          >
            <div class="fsize12" :id="`${index}_holdings_table_div_qty`">
              Qty.
              <span v-if="parseInt(item.Btst) != 0">
                <span v-if="parseInt(item.showQty) != 0"
                  >{{ item.showQty }} &nbsp;</span
                >( T1: {{ item.Btst }} )</span
              >
              <span v-if="parseInt(item.Btst) == 0">{{ item.showQty }}</span
              >| Avg. {{ item["Price"] }}
            </div>
            
            <div v-if=" newtag == 0"
              :id="`${index}_holdings_table_div_perchg`"
              class="fsize12"
              :class="
                parseFloat(item.perChg) >= 0 ? 'positiveColor' : 'negativeColor'
              "
            >
              {{ item.perChg }} %
            </div>
            <div v-else
              :id="`${index}_holdings_table_div_perchg`"
              class="fsize12"
              :class="
                parseFloat(item.dayChg) >= 0 ? 'positiveColor' : 'negativeColor'
              "
            >
              {{ item.dayChg }} %
            </div>
          </div>
          <div
            class="row my-2 mx-0 pa-0 d-flex align-center justify-space-between w-100"
          >
            <div class="fsize14" :id="`${index}_holdings_table_div_scripName`">
              {{ item.formatScripName }}
            </div>
            <div  v-if=" newtag == 0"
              :id="`${index}_holdings_table_div_pnl`"
              class="fsize12"
              :class="
                parseFloat(item.pnl) >= 0 ? 'positiveColor' : 'negativeColor'
              "
            >
              {{ ruppesFormat(item.pnl) }}
            </div>
            <div  v-else
              :id="`${index}_holdings_table_div_pnl`"
              class="fsize12"
              :class="
                parseFloat(item.daypnl) >= 0 ? 'positiveColor' : 'negativeColor'
              "
            >
              {{ ruppesFormat(item.daypnl) }}
            </div>
          </div>
          <div
            class="row pa-0 ma-0 d-flex align-center justify-space-between w-100"
          >
            <div class="fsize12" :id="`${index}_holdings_table_div_invest_val`">
              Invested {{ item["buyValue"] }}
            </div>
            <div class="fsize12" :id="`${index}_holdings_table_div_lp`">
              LTP {{ item.ltp }}
            </div>
          </div>
        </div>
      </div>
    </v-card>

    <div
      class="d-flex flex-column justify-center align-center vh-70"
      v-if="
        (!!holdingList &&
          holdingList.length == 0 &&
          this.searchHolding == '' &&
          !this.fundsOrHoldingsLoader) ||
        (!!holdingList &&
          holdingSearchFilter.length == 0 &&
          this.searchHolding !== '' &&
          !this.fundsOrHoldingsLoader)
      "
    >
      <div>
        <img
          id="holdings_no_data_img"
          src="../assets/images/noData.svg"
          alt=""
          class="w-300 h-170 mb-4"
        />
      </div>
      <div id="holdings_no_data_val">NO HOLDINGS FOUND</div>
    </div>
    <bottomsheet
      :currentData="currentSheetData"
      v-on:from-child="getChildData"
    />

    <v-dialog v-model="cdslDialog" scrollable width="600px" max-width="600px">
      <cdslPage />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import cdslPage from "../components/commonauthorize.vue";
import commonFunc from "../mixins/commonFunctions";
import window from "../mixins/orderWindow";
import bottomsheet from "../components/bottomSheet.vue";
import customIcon from "../components/customIcon.vue";
export default {
  mixins: [commonFunc, window],
  data: () => ({
    searchHolding: "",
    infoType: "Stocks",
    isDropped: false,
    currentIndex: -1,
    isRotate: true,
    currentSheetData: [],
    lightheaders: [
      {
        text: "Instrument",
        align: "left",
        value: "formatScripName",
        sortable: true,
        class: "text-left lightselectRow",
        align: "d-flex",
        subClass: "ml-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "Qty.",
        align: "right",
        value: "showQty",
        sortable: true,
        class: "text-right lightselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "Buy Avg.",
        align: "right",
        value: "Price",
        sortable: true,
        class: "text-right lightselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "Buy Value",
        align: "right",
        value: "buyValue",
        sortable: true,
        class: "text-right lightselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "LTP",
        align: "right",
        value: "ltp",
        sortable: true,
        class: "text-right lightselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "PDC",
        align: "right",
        value: "pdc",
        sortable: true,
        class: "text-right lightselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "Net P&L",
        align: "right",
        value: "pnl",
        sortable: true,
        class: "text-right lightselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "Day's P&L",
        align: "right",
        value: "daypnl",
        sortable: true,
        class: "text-right lightselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "% chg.",
        align: "right",
        value: "perChg",
        sortable: true,
        class: "text-right lightselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
    ],
    darkheaders: [
      {
        text: "Instrument",
        align: "left",
        value: "formatScripName",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "ml-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "Qty.",
        align: "right",
        value: "showQty",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "Buy Avg.",
        align: "right",
        value: "Price",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "Buy Value",
        align: "right",
        value: "buyValue",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "LTP",
        align: "right",
        value: "ltp",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "PDC",
        align: "right",
        value: "pdc",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "Net P&L",
        align: "right",
        value: "pnl",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "Day's P&L",
        align: "right",
        value: "daypnl",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
      {
        text: "% chg.",
        align: "right",
        value: "perChg",
        sortable: true,
        class: "text-right darkselectRow",
        align: "d-flex",
        subClass: "mr-auto w-16",
        isSort: false,
        isdesc: true,
      },
    ],
    holdingsHeaderSelection: {
      formatScripName: {
        text: "Instrument",
        value: "formatScripName",
        selected: true,
      },
      showQty: {
        text: "Qty.",
        value: "showQty",
        selected: true,
      },
      Price: {
        text: "Buy Avg.",
        value: "Price",
        selected: true,
      },
      buyValue: {
        text: "Buy Value",
        value: "buyValue",
        selected: true,
      },
      ltp: {
        text: "LTP",
        value: "ltp",
        selected: true,
      },
      pdc: {
        text: "PDC",
        value: "pdc",
        selected: true,
      },
      pnl: {
        text: "P&L",
        value: "pnl",
        selected: true,
      },
      daypnl: {
        text: "Day's P&L",
        value: "daypnl",
        selected: true,
      },
      perChg: {
        text: "% chg.",
        value: "perChg",
        selected: true,
      },
    },
    pagination: {
      sortBy: null,
      descending: false,
    },
    isFooter: true,
    headerIndex: -1,
    menu: false,
    headers: [],
    tags: ["Net P&L", `Day's P&L`],
    newtag: 0,
  }),
  components: {
    bottomsheet,
    customIcon,
    cdslPage,
  },
  methods: {
    callAuthDialog(where) {
      this.$store.commit("holdings/setPludgeDataArray", "");
      this.$store.commit("holdings/setSelectcheckData", []);
      where == "Auth" ? (this.isRevoke = false) : (this.isRevoke = true);

      this.authorizeList.forEach((el) => {
        el.tempQty = el.SellableQty;
      });
      this.cdslDialog = true;
    },
    changeSort(column) {
      column == 'perChg' && this.newtag == 1  ? column = 'dayChg' : ''
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
      this.getDataFromApi();
    },
    getDataFromApi() {
      return new Promise((resolve, reject) => {
        const { sortBy, descending } = this.pagination;

        let items = this.holdingList;

        if (sortBy) {
          items = items.sort((a, b) => {
            const sortA =
              sortBy == "formatScripName" ? a[sortBy] : parseFloat(a[sortBy]);
            const sortB =
              sortBy == "formatScripName" ? b[sortBy] : parseFloat(b[sortBy]);
            if (descending) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              return 0;
            } else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              return 0;
            }
          });
        }
        if (this.$vuetify.theme.dark) {
          // this.darkheaders = this.headers
          this.headers.findIndex((idex) => {
            idex.value == sortBy ? (idex.isSort = true) : (idex.isSort = false);
            idex.value == sortBy ? (idex.isdesc = descending) : "";
          });
        } else {
          // this.headers = this.lightheaders
          this.headers.findIndex((idex) => {
            idex.value == sortBy ? (idex.isSort = true) : (idex.isSort = false);
            idex.value == sortBy ? (idex.isdesc = descending) : "";
          });
        }
      });
    },
    getChildData(data) {
      if (data["page"] == "Holdings") {
        if (data["action"] == "Add" || data["action"] == "Exit") {
          this.callOrderWindow(
            data["action"] == "Add" ? "buy" : "sell",
            data.item
          );
        }
        if (data["action"] == "chart") {
          this.callTrandingViewChart(data.item, "holdings");
        }
      }
    },
    callBottomSheet(val) {
      this.currentSheetData = [];
      var tempData = {
        where: "Holdings",
        isOpen: true,
        item: val,
      };
      this.currentSheetData.push(tempData);
    },
    getWindowHeight(id) {
      var offsetHeight =
        this.$store.state.windowHeight -
        document.getElementById(id).getBoundingClientRect().top;
      var dropdownHeight = 100;
      offsetHeight < dropdownHeight
        ? (this.isRotate = true)
        : (this.isRotate = false);
    },
    // call order window
    async callOrderWindow(orderType, value) {
      this.callOrderWindowByContract(
        value,
        orderType,
        false,
        "holdings",
        false
      );
    },

    async colUpdate() {
      try {
        let headerData = this.$vuetify.theme.dark
          ? this.darkheaders
          : this.lightheaders;
        this.headers = [];
        for (let header of headerData) {
          if (this.holdingsHeaderSelection[header.value].selected) {
            this.headers.push(header);
          }
        }
      } catch (err) {
      }
    },

    async changeTag(value) {
      if (value == "Net P&L") { 
         this.holdingsHeaderSelection.pnl.selected = true;
        this.holdingsHeaderSelection.Price.selected = true;
        this.holdingsHeaderSelection.pdc.selected = false;
        this.holdingsHeaderSelection.daypnl.selected = false;
        await this.colUpdate();
      } else {
        this.holdingsHeaderSelection.pdc.selected = true;
        this.holdingsHeaderSelection.daypnl.selected = true;
        this.holdingsHeaderSelection.pnl.selected = false;
        this.holdingsHeaderSelection.Price.selected = false;
        await this.colUpdate();
      }
    },
  },
  mounted() {
    this.$store.dispatch("holdings/getHoldings");
    this.headers = this.$vuetify.theme.dark
      ? this.darkheaders
      : this.lightheaders;
    this.changeTag("Net P&L");
  },
  computed: {
    ...mapState("holdings", [
      "holdingList",
      "tabList",
      "totalInvestment",
      "totalcurrentValue",
      "totalPnl",
      "totalPnlChange",
      "daysPnl",
      "daysPnlChange",
      "cdslLoader",
      "pludgeTransId",
      "pludgeDpId",
      "pludgeReqId",
      "pludgeVersion",
      "pludgeUrl",
      "authorizeList",
      "revocationList",
    ]),
    ...mapState("settings", ["userDetails"]),
    ...mapState(["fundsOrHoldingsLoader"]),
    holdingSearchFilter() {
      return this.holdingList.filter((post) => {
        return (
          post.Nsetsym.toLowerCase().includes(
            this.searchHolding.toLowerCase()
          ) ||
          post.Bsetsym.toLowerCase().includes(this.searchHolding.toLowerCase())
        );
      });
    },
    cdslDialog: {
      get: function () {
        return this.$store.state.holdings.cdslDialog;
      },
      set: function (newValue) {
        this.$store.commit("holdings/setCdslDialog", newValue);
      },
    },
    isRevoke: {
      get: function () {
        return this.$store.state.holdings.isRevoke;
      },
      set: function (newValue) {
        this.$store.commit("holdings/setIsRevoke", newValue);
      },
    },
  },
};
</script>

<style>
.settingsMenu {
  top: 126px !important;
  left: 1053px;
}
.addRemoveCard {
  box-shadow: none !important;
}
</style>
